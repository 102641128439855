<template>
  <div class="login">
    <div class="tit">
      <h3>Sign in</h3>
    </div>
    <el-form class="login-form" ref="loginForm" :model="form" :rules="rules" >
      <el-form-item prop="user">
        <p class="label">
          <i class="iconfont iconshouji"></i>
          <label>Email</label>
        </p>
        <el-input
                v-model="form.user"
                auto-complete="off"
                placeholder="Please input email"
                @keyup.enter.native="setPasswordFocus"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <p class="label">
          <i class="iconfont iconmima1"></i>
          <label>Password</label>
        </p>
        <el-input
            ref="password"
            v-model="form.password"
            auto-complete="off"
            placeholder="Please input password"
            @keyup.enter.native="submitLogin"
            show-password
        ></el-input>
      </el-form-item>
      <el-form :inline="true">
        <el-form-item class="btn">
          <a class="btn-black" @click="submitLogin">Sign in</a>
        </el-form-item>
        <el-form-item class="btn">
          <a class="btn-border" @click="register">
            <i class="iconfont iconxiugai"></i>
            <span>Sign up</span>
          </a>
        </el-form-item>
      </el-form>
      <el-form-item>
        <div class="text-small">
        Forget password，<a class="link" @click="findPassword">Find password</a>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { loginUser } from "../../../api/auth";
import regexJs from "../../../utils/regex";
import { setToken } from "../../../utils/storage";
import { message } from '@/utils/resetMessage';

export default {

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        this.isError = "";
        callback(new Error("please input password"));
      } else {
        callback();
      }
    };
    return {
      form: {
        user: "",
        password: "",
        remember: "on",
        type: constants.loginNameType.email
      },
      rules: {
        user: [
          { required: true, trigger: "blur", message: "please input email or phone" }
        ],
        password: [
          { required: true, validator: validatePass, message: "please input password" }
        ]
      },
      passwordFocus: false,
      isError: ""
    };
  },
  methods: {
    submitLogin() {
        this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.judgeUserType();
          this.$showLoading(false,false,"Loading...");
          loginUser(this.form)
            .then(result => {
              this.$hideLoading();
              this.$store.commit('loginNameType', this.form.type);
              this.$store.dispatch("setUserInfo", result.account);
              this.$store.commit("token", result.token);
              //this.$store.commit("updateShowAdvert", true);
              setToken(result.token);
              //返回到上一个页面
              this.$router.push({ path: "/materialDownload" });
            })
            .catch(error => {
              this.$hideLoading();
              message.error({
                message: error,
                duration: 4000,
                offset: 80
              })
              this.isError = error;
            });
        }

      });
    },
    judgeUserType() {
      this.form.type = regexJs.email.test(this.form.user)
        ? constants.loginNameType.email
        : constants.loginNameType.phone;
    },
    findPassword() {
      this.$router.push({ path: "/reset" });
    },
    register() {
      this.$router.push({ path: "/registered" });
    },
    setPasswordFocus() {
      if (this.form.user !== "") this.$refs.password.focus();
    }
  }
};
</script>
<style lang="scss" scoped>
.login {
  padding-top: 50px;
  .tit {
    margin-bottom: 50px;
    overflow: hidden;
    h3 {
      float: left;
      font-size: 24px;
      color: #1872f9;
    }
    .wei {
      float: right;
      line-height: 30px;
      color: #7e7e7e;
      font-size: 14px;
      cursor: pointer;
      .iconfont {
        margin-right: 10px;
        color: #5acd54;
        font-size: 16px;
      }
    }
  }
}
.el-form-item__error.error {
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
  line-height: 0;
  margin-top: -20px;
}
</style>
